/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.split.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
window.onload = function () {};

import TransferDom from 'vux/src/directives/transfer-dom';
import { Dialog } from 'vant';
export default {
  name: 'OrderPayResult',
  directives: {
    TransferDom: TransferDom
  },
  components: {},
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    if (from.name !== 'OrderPay') {
      window.vm.$utils.Store.setItem('showOrders', false);
      next();
    } else {
      window.vm.$utils.Store.setItem('showOrders', true);
      next();
    }
  },
  data: function data() {
    return {
      spareCol1: '',
      goods: [],
      foods: [],
      showPay: true,
      orderDate: '',
      orderDate2: '',
      //下单时间
      orderDate3: '',
      //完成时间
      shareTime: '',
      //共享时间
      receiveTime: '',
      //领取时间
      unShareTime: '',
      //取消共享时间
      toPayTime: '',
      //支付时间
      orderState: '',
      shareFlag: '',
      orderId: '',
      orderPrice: '',
      orderNum: '',
      addrName: '',
      sendFlag: false,
      //需否送餐
      showOrders: this.$utils.Store.getItem('showOrders'),
      mealId: null,
      overdue: '',
      operate: '0',
      takeFlag: '',
      cancelFlag: '',
      placeName: '',
      merchantName: '',
      //窗口名称
      week: '',
      weekDay: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      AllPageHeight: '',
      //当前页面高度
      windowHeight: '' //可视高度

    };
  },
  watch: {
    AllPageHeight: function AllPageHeight(val) {
      if (this.windowHeight > val) {
        document.querySelector('.pay-result').style.height = this.windowHeight + 'px';
      }
    }
  },
  methods: {
    // 撤销订单
    cancelOrder: function cancelOrder() {
      var _this = this;

      this.$utils.Tools.confirm(function () {
        _this.$api.Meal.updateByPrimaryKey({
          subOrderId: _this.orderId,
          mealState: '2'
        }).then(function (response) {
          _this.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg;

          if (+code !== _this.$code.success) {
            _this.$utils.Tools.toastShow(msg);
          } else {
            _this.$utils.Tools.toastShow(msg);

            setTimeout(function () {
              _this.$router.replace('/OrderRecord');
            }, 1000);
          }
        }).catch(function () {
          _this.$utils.Tools.httpError();
        });
      }, '确定撤销订单吗？');
    },
    //手动取餐
    getOrder: function getOrder() {
      var _this2 = this;

      Dialog.confirm({
        title: '提示',
        message: '确定手动取餐吗？',
        confirmButtonColor: '#3399ff'
      }).then(function () {
        _this2.$api.Meal.updateByPrimaryKey({
          subOrderId: _this2.orderId,
          mealState: '3'
        }).then(function (response) {
          _this2.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg;

          if (+code !== _this2.$code.success) {
            _this2.$utils.Tools.toastShow(msg);
          } else {
            _this2.$utils.Tools.toastShow(msg);

            setTimeout(function () {
              _this2.$router.replace('/OrderRecord');
            }, 1000);
          }
        }).catch(function () {
          _this2.$utils.Tools.httpError();
        });
      }).catch(function () {});
    },
    //获取订单详情
    getOrderDetail: function getOrderDetail() {
      var _this3 = this;

      this.$utils.Tools.loadShow();
      this.$api.Meal.getOrderDetail(this.orderId, this.mealId).then(function (response) {
        // this.$utils.Tools.loadHide()
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          _this3.goods = data.mealList;
          _this3.spareCol1 = _this3.goods[0].spareCol1;
          _this3.foods = data.foodList;
          _this3.addrName = data.addrName;
          _this3.orderDate = data.orderDate;
          _this3.orderDate2 = data.ctDate;
          _this3.receiveTime = data.receiveTime;
          _this3.toPayTime = data.toPayTime;
          _this3.unShareTime = data.unShareTime;
          _this3.orderDate3 = data.ltDate;
          _this3.shareTime = data.shareTime;
          _this3.orderState = data.orderState;
          _this3.shareFlag = data.shareFlag;
          _this3.orderId = data.orderId;
          _this3.orderPrice = data.orderPrice;
          _this3.orderNum = data.foodNum;
          _this3.sendFlag = data.sendFlag;
          _this3.operate = data.operate;
          _this3.takeFlag = data.takeFlag;
          _this3.cancelFlag = data.cancelFlag;
          _this3.placeName = data.placeName;
          _this3.merchantName = _this3.foods.length > 0 && _this3.foods[0].merchantName || '';

          _this3.fade();

          var date = new Date(_this3.orderDate);
          _this3.week = _this3.weekDay[date.getDay()];
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    historyClick: function historyClick() {
      this.$router.push('/OrderRecord');
    },
    fade: function fade() {},
    toDecimal2: function toDecimal2(x) {
      var f = parseFloat(x);

      if (isNaN(f)) {
        return false;
      }

      f = Math.round(x * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf('.');

      if (rs < 0) {
        rs = s.length;
        s += '.';
      }

      while (s.length <= rs + 2) {
        s += '0';
      }

      return s;
    },
    toFont: function toFont(num) {
      num = num.substring(0, 10);
      return num;
    }
  },
  created: function created() {
    var _this4 = this;

    this.$utils.Tools.loadShow();
    setTimeout(function () {
      _this4.windowHeight = document.querySelector('body').clientHeight;
      _this4.AllPageHeight = document.getElementsByClassName('pay-result')[0].clientHeight;

      if (_this4.windowHeight > _this4.AllPageHeight) {
        document.querySelector('.pay-result').style.height = _this4.windowHeight + 'px';
      }

      _this4.$utils.Tools.loadHide();
    }, 1000);
    var strs = [];

    if (this.$route.query.orderId) {
      this.orderId = this.$route.query.orderId;
      this.mealId = this.$route.query.mealId;
      this.overdue = this.$route.query.overdue;
    } else {
      this.$utils.Store.setItem('state', this.$route.params.state);
      strs = decodeURI(this.$route.params.state).split(',');
      this.orderId = strs[0];
      this.mealId = strs[1];
      this.overdue = strs[2];
    }

    this.getOrderDetail();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {
    this.$utils.Store.removeItem('state');
  }
};